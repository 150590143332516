<template>
  <component
    :is="componentType"
    class="os-button"
    data-locator="os-button"
    data-testid="os-button"
    v-bind="{ ...buttonOptions, ...$attrs }"
    :class="buttonClasses"
    :disabled="isDisabled || isLoading"
  >
    <OsIcon
      v-if="isLoading"
      name="Loader"
      size="1.25rem"
      class="text-gray-400"
    />
    <slot />
  </component>
</template>

<script lang="ts">
export enum EIconSides {
  Left = 'left',
  Right = 'right'
}

export enum EButtonThemes {
  back = 'back',
  filter = 'filter',
  view360 = 'view360',
  close = 'close',
  scrollToTop = 'scroll-to-top',
  cart = 'cart'
}

export default {
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isExternalLink: {
      type: Boolean,
      required: false,
      default: false
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isTransparent: {
      type: Boolean,
      required: false,
      default: false
    },
    hasPadding: {
      type: Boolean,
      required: false,
      default: true
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: true
    },
    hasAnimation: {
      type: Boolean,
      required: false,
      default: true
    },

    theme: {
      type: String as PropType<EButtonThemes>,
      required: false,
      default: ''
    }
  },

  setup(props) {
    const buttonClasses = computed(() => {
      const defaults = {
        'os-button--transparent': props.isTransparent,
        'os-button--primary': props.isPrimary,
        'os-button--disabled': props.isDisabled || props.isLoading,
        'os-button--no-pad': !props.hasPadding,
        'os-button--no-shadow': !props.hasShadow,
        'os-button--no-animation': !props.hasAnimation
      }

      switch (props.theme) {
        case 'back':
          defaults['os-button--t-back'] = true
          defaults['os-button--no-pad'] = true
          break
        case 'filter':
          defaults['os-button--t-filter'] = true
          break
        case 'view360':
          defaults['os-button--t-view360'] = true
          break
        case 'scroll-to-top':
          defaults['os-button--t-scroll-to-top'] = true
          break
        case 'close':
          defaults['os-button--t-close'] = true
          defaults['os-button--no-pad'] = true
          break
      }

      return defaults
    })

    const iconOptions = computed(() => {
      const iconOptions: any = {
        fill: 'currentColor',
        stroke: null,
        rotate: null,
        name: props.theme || ''
      }

      switch (props.theme) {
        case 'back':
          iconOptions.name = 'arrow'
          iconOptions.rotate = 90
          break
        case 'filter':
          iconOptions.name = 'filters'
          break
        case 'close':
          iconOptions.name = 'close'
          break
        case 'view360':
          iconOptions.name = 'arrow360'
          break
        case 'cart':
          iconOptions.name = 'cart'
          break
      }

      return iconOptions
    })

    const buttonOptions = computed(() => {
      return props.link
        ? {
            target: props.isExternalLink ? '_blank' : '_self',
            rel: props.isExternalLink ? 'noopener' : null,
            href: props.link
          }
        : {}
    })

    const componentType = computed(() => (props.link ? 'a' : 'button'))

    return {
      buttonClasses,
      iconOptions,
      buttonOptions,
      componentType
    }
  }
}
</script>

<style lang="scss">
.os-button {
  height: rem(40);
  min-height: rem(24);
  width: fit-content;
  padding: 0 rem(16);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  @apply text-blue-700;
  background-color: $grey--lightest;
  font-family: $mb-font-text;
  font-size: rem(18);
  white-space: nowrap;
  letter-spacing: -0.2px;
  text-align: center;
  user-select: none;
  min-width: auto;
  text-decoration: none;

  svg {
    flex: 0 0 auto;
  }

  &:not(.os-button--no-animation):not(.inline-button) {
    @include transitions();
  }

  @include viewport('xl') {
    min-width: 148px;
  }

  &.inline-button {
    display: inline-flex;
    padding: 0;
    background: transparent;
    min-width: 0;
    height: auto;

    &.bold {
      font-family: $mb-font-text-bold;
      font-weight: normal;
    }
  }
}

.os-button--no-shadow {
  box-shadow: none !important;
}

.os-button--primary {
  color: $white;
  background-color: $blue;
  text-shadow: 0 0 2px $blue--dark;

  &:focus {
    outline-offset: 4px;
  }
}

.os-button--t-filter {
  margin-right: rem(16);
  border-color: $grey--light;
  border: 1px solid $grey--light;
  background-color: transparent;

  @include viewport('sm') {
    min-width: auto;
  }

  svg {
    color: $blue;
  }

  span {
    color: $blue;
  }
}

.os-button--t-back {
  color: $grey--darkest;
  background-color: transparent;

  svg {
    color: $blue;
  }
}

.os-button--t-close {
  min-width: auto;
  color: $grey--darkest;
  background-color: transparent;
  &:only-child {
    margin-left: auto;
  }
  svg {
    width: 16px;
    height: 16px;
    color: $blue;
  }
  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.os-button--t-view360 {
  position: fixed;
  top: 64px;
  left: 16px;
  z-index: 1;
  padding: 0 rem(8) 0 0;
  min-width: auto;
  width: auto;

  @include viewport('lg') {
    position: absolute;
    top: 16px;
    left: 24px;
    z-index: 1;
  }

  svg {
    position: relative;
    margin-right: 0;
    width: 58px;
  }
}

.os-button--transparent {
  background: transparent;
  color: $white;
  border-color: currentColor;
}

.os-button--block {
  display: flex;
  width: 100%;
}

.os-button--no-pad {
  padding: 0;
}

.os-button--disabled,
.os-button--disabled[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
</style>
